.tabs{
    display: flex;
    height: fit-content ;
    width: fit-content;
}

.tabs>.tab{
    color: var(--main-blue);
    padding: 10px 20px;
    /* background-color: var(--light-blue); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 400;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.tabs>.tab:hover{
    background-color: var(--light-blue);
}
.tabs>.tab.active{
    background-color: var(--light-blue);
    /* cursor: pointer; */
}

.tab-content.active{
    display: flex;
}
.tab-content{
    display: none;
}
.tabs-container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.tabs-container>.nav-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid var(--main-blue) ;
}