/* Popup.css */

.popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.popup-main {
    position: fixed;
    background-color: white;
    width: 500px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-main>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.popup-main>div>svg{
    width: 50px;
    height: 50px;
}
.popup-main>.success{
    color: var(--green-cyan);
    font-size: 20px;
}
.popup-main>.error{
    color:#FF002E;
    font-size: 20px;
}
.popup-main>.success>button{
    width: 120px;
    height: 40px;
    background-color: var(--green-cyan);
    color: white;
    font-size: 24px;
    cursor: pointer;
    border-radius: 8px;
}
.popup-main>.error>button{
    width: 120px;
    height: 40px;
    background-color:#FF002E;
    color: white;
    font-size: 24px;
    cursor: pointer;
    border-radius: 8px;
}
.display-block {
    display: block;
}

.display-none {
    display: none;
}