.unpublished {
    display: flex;
    flex-direction: column;
    gap: 36px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.unpublished>.unpublished-title {
    display: flex;
    color: var(--main-blue);
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.unpublished>.unpublished-title>div {
    height: 1px;
    width: 100%;
    background-color: var(--main-blue);
}

.unpublished>.unpublished-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}
.unpublished>.unpublished-container>tbody{
    width: 100%;
    height: 550px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #33BBFF #C2EBFF;
}
/* .unpublished>.unpublished-container>tbody::-webkit-scrollbar{
    background-color: #EBF8FF;
    border-radius: 2px;
} */
.unpublished>.unpublished-container>tr , .unpublished>.unpublished-container>tbody>tr{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.unpublished>.unpublished-container>.unpublished-header {
    margin-bottom: 17.5px;
}

.unpublished>.unpublished-container>.unpublished-header>th {
    font-size: 16px;
    font-weight: 400;
    width: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #006699;
}

.unpublished>.unpublished-container>tbody>.unpublished-file>td {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 8.75px 17.5px;
    color: #006699;
    justify-content: flex-start;
}

.unpublished>.unpublished-container>tbody>.unpublished-file>#type {
    border-radius: 20px;
    background-color: #99DDFF;
    border: 1px solid #006699;
    min-width: 118px;
    justify-content: center;

}

.unpublished>.unpublished-container>tbody>.unpublished-file {
    display: flex;
    width: 100%;
    padding: 17.5px;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-bottom: 1px solid #33BBFF;
}

.unpublished>.action {
    padding: 17.5px 20px;
    background-color: #C2EBFF;
    color: #33BBFF;
    cursor: not-allowed;
    opacity: 0.3;
    transition: 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8.75px;
    height: fit-content;
    font-size: 24px;
}

.unpublished>.action.active {
    opacity: 1;
    cursor: pointer;
}

.unpublished>.unpublished-container>tbody>.unpublished-file:hover {
    background-color: #C2EBFF;
}

.unpublished>.unpublished-container>tbody>.unpublished-file.active {
    background-color: #C2EBFF;
}

.unpublished>.action.active:hover {
    background-color: #33BBFF;
    color: #EBF8FF;
}

.unpublished>.action>svg>path {
    transition: 0.3s ease-in-out;
}

.unpublished>.action.active:hover svg>path {
    fill: #EBF8FF;
}