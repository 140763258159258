.prices {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100vw;
    height: 100vh;
    justify-content: flex-start;
    gap: 36px;
    overflow: hidden;
}

.displayed-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 24px;
    width: 100%;
}