.published{
    display: flex;
    flex-direction: column;
    gap: 36px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.published>.published-title{
    display: flex;
    color: var(--main-blue);
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.published>.published-title>div{
    height: 1px;
    width: 100%;
    background-color: var(--main-blue);
}
.published>.published-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.published>.published-container>tr{
    display: flex;
    justify-content: space-between;
    width: 100%;
    
}
.published>.published-container>.published-header{
    margin-bottom: 17.5px;
}
.published>.published-container>.published-header>th{
    font-size: 16px;
    font-weight: 400;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #006699;
}
.published>.published-container>.published-file{
    width: 100%;
    padding: 17.5px;
    background-color: #D6F1FF;
    border-bottom: 1px solid #33BBFF;
}
.published>.published-container>.published-file>td{
    font-size: 16px;
    font-weight: 400;
    padding: 8.75px 17.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #006699;
}
.published>.published-container>.published-file>#type{
    border-radius: 20px;
    background-color: #99DDFF;
    border: 1px solid #006699;
   min-width: 118px;
}