.profile-container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
}

.profile-container>.profile {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 300;
    color: var(--main-blue);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.profile-container>.profile:hover {
    background-color: var(--light-blue);
}

.profile-container>.profile.active {
    background-color: var(--light-blue);
}

.profile-container>.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 300;
    position: absolute;
    z-index: 1;
    width: 100%;
    /* opacity: 0; */
    transform: translate(200px, 55px);
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
}

.profile-container>.menu.active {
    z-index: 1;
    opacity: 1;
    transform: translate(0px,55px);
}

.profile-container>.menu>.register {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #EEFFE5;
    color: #113300;
}

.profile-container>.menu>.logout {
    cursor: pointer;
    color: var(--red);
    padding: 10px 20px;
    background-color: #FFDBDB;
    width: 100%;
}