.ovokees{
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.ovokees>.register{
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 17.5px;
    padding: 17.5px;
    font-size: 24px;
    font-weight: 400;
    background-color: #33BBFF;
    color: white;
    cursor: pointer;
}
.ovokees>.ovokees-container{
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    /* flex-direction: column; */
}
.ovokees>.ovokees-container>.ovokees-table{
    width: 100%;
    border-spacing: 0px;
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}
.ovokees>.ovokees-container>.ovokees-table.active{
    width: 72%;
}
.ovokees>.ovokees-container>.ovokees-table>tr{
    width: 100%;
    height: 54px;
    text-align: left;
    position: relative;
    border-bottom: 1px solid #33BBFF;
    display: flex;
    align-items: center;
}
.ovokees>.ovokees-container>.ovokees-table>tr>th{
    color: #2483B2;
    font-size: 16px;
    padding: 17.5px;
    font-weight: 300;
    width: 100%;
}
.ovokees>.ovokees-container>.ovokees-table>tbody{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #33BBFF #C2EBFF;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr {
    background-color: #E5F6FF;
    position: relative;
    transition: 0.3s ease-in-out;
    border-bottom: 1px solid #33BBFF;
    display: flex;
    align-items: center;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr.active {
    background-color: #a7e2ff;
    position: relative;
    width: 100%;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>td {
    padding: 17.5px;
    color: #2483B2;
    width: 100%;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>.edit-button {
   position: absolute;
   right: 65px;
   top: 15.5px;
   padding: 8.75px;
   background-color: transparent;
   cursor: pointer;
   border-radius: 12px;
   transition: 0.3s ease-in-out;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>.edit-button:hover{
    background-color: #C2EBFF;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>.edit-button.active{
    background-color: #C2EBFF;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>.password-button {
    position: absolute;
    right: 20px;
    top: 19.5px;
    padding: 8.75px;
    background-color: transparent;
    cursor: pointer;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>.password-button:hover{
    background-color: #C2EBFF;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>.password-button.active{
    background-color: #C2EBFF;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>td>.role {
    padding: 8.75px 17.5px;
    font-size: 12px;
    color: #195E80;
    background-color: #ADE4FF;
    border-radius: 20px;
    width: 72px;
    border: 1px solid #2996CC;
    width: fit-content;
}
.ovokees>.ovokees-container>.ovokees-table>tbody>tr>td>.role.admin {
    padding: 8.75px 17.5px;
    font-size: 12px;
    color: #DEF4FF;
    background-color: #33BBFF;
    border-radius: 20px;
    width: 72px;
}
.ovokees>.ovokees-container>.side-register-div, 
.ovokees>.ovokees-container>.side-edit-div,
.ovokees>.ovokees-container>.side-update-div
{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: #D6F1FF;
    width: 500px;
    position: absolute;
    right: 0px;
    transform: translateX(530px);
    transition: 0.3s ease-in-out;
}
.ovokees>.ovokees-container>.side-register-div.active, 
.ovokees>.ovokees-container>.side-edit-div.active,
.ovokees>.ovokees-container>.side-update-div.active
{
    transform: translateX(0px);
}
.ovokees>.ovokees-container>.side-register-div>.header,
.ovokees>.ovokees-container>.side-edit-div>.header,
.ovokees>.ovokees-container>.side-update-div>.header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #33BBFF;
    font-weight: 400;
}
.ovokees>.ovokees-container>.side-register-div>.header>button,
.ovokees>.ovokees-container>.side-edit-div>.header>button,
.ovokees>.ovokees-container>.side-update-div>.header>button
{
    padding: 8.75px;
    background-color: transparent;
    cursor: pointer;
}
.ovokees>.ovokees-container>.side-register-div>.register-form,
.ovokees>.ovokees-container>.side-edit-div>.register-form,
.ovokees>.ovokees-container>.side-update-div>.register-form
{
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.ovokees>.ovokees-container>.side-register-div>.register-form>.input-container,
.ovokees>.ovokees-container>.side-edit-div>.register-form>.input-container,
.ovokees>.ovokees-container>.side-update-div>.register-form>.input-container
{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
.ovokees>.ovokees-container>.side-register-div>.register-form>.input-container>div,
.ovokees>.ovokees-container>.side-edit-div>.register-form>.input-container>div,
.ovokees>.ovokees-container>.side-update-div>.register-form>.input-container>div
{
    background-color: #E5F6FF;
    width: 100%;
}
.ovokees>.ovokees-container>.side-register-div>.register-form>.input-container>div>.login-input:placeholder{
    font-size: 16px;
}
.ovokees>.ovokees-container>.side-register-div>.register-form>button,
.ovokees>.ovokees-container>.side-edit-div>.register-form>button,
.ovokees>.ovokees-container>.side-update-div>.register-form>button
{
    padding: 16px;
    font-size: 16px;
    color: white;
    background-color: #33BBFF;
    cursor: pointer;
}


.not-auth{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    color: #33BBFF;
    width: 100%;
    height: 100%;
}

.ovokees>.error-message{
    font-size: 20px;
    color: rgb(206, 63, 63);
    width: 100%;
    background-color: #e6cdcd;
    padding: 17.5px 8.75px;
}
.ovokees>.success-message{
    font-size: 20px;
    color: green;
    width: 100%;
    background-color: #c1d6c1;
    padding: 17.5px 8.75px;
}