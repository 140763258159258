.leads {
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
}

.leads>.leads-table {
    width: 100%;
    border-spacing: 0px;
    transition: 0.3s ease-in-out;
}
.leads>.leads-table.active{
    width: 74%;
}
.leads>.leads-table>tr {
    width: 100%;
    height: 54px;
    text-align: left;
    position: relative;
}

.leads>.leads-table>tr>th {
    color: #2483B2;
    font-size: 16px;
    font-weight: 300;
    border-bottom: 1px solid #33BBFF;
    padding: 17.5px;
}

.leads>.leads-table>tr>.more {
    position: absolute;
    right: 17.5px;
    top: 13%;
    padding: 12px;
    background-color: transparent;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.leads>.leads-table>tr>.more:hover {
    background-color: #C2EBFF;
}
.leads>.leads-table>tr>.more.active {
    background-color: #C2EBFF;
}
.leads>.leads-table>.body{
    background-color: #E5F6FF;
}
.leads>.leads-table>tr>td {
    padding: 17.5px;
    color: #2483B2;
    border-bottom: 1px solid #33BBFF;
}
.leads>.leads-table>tr>td.FName , .leads>.leads-table>tr>td.LName{
    text-transform: capitalize;
}

.leads>.leads-table>.body.active{
    background-color: #D6F1FF;
    
}

.leads>.side-content {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    align-items: flex-start;
    background-color: #D6F1FF;
    position: absolute;
    right: 0;
    transform: translateX(125%);
    transition: 0.3s ease-in-out;
    top: 24%;
}
.leads>.side-content.active{
    transform: translateX(0%);
}

.leads>.side-content>p {
    font-size: 16px;
    color: #33BBFF;
}
.leads>.side-content>.header{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.leads>.side-content>div {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    border: 1px solid #33BBFF;
    width: 100%;
    background-color: #E5F6FF;
}
::-webkit-scrollbar-thumb {
    background: #E5F6FF; 
    border-radius: 2px;
  }

.leads>.side-content>div>.bottom-quote {
    align-self: flex-end;
    transform: rotate(180deg);
}

.leads>.side-content>div>p {
    font-style: italic;
    font-size: 16px;
    color: #004C99;
}

.leads>.side-content>.date {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.leads>.side-content>.header>.close{
    background-color: transparent;
    cursor: pointer;
}