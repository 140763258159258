.upload-button{
    position: relative;
    padding: 17.5px;
    width: fit-content;
    transition: 0.3s ease-in-out;
    background-color: #33BBFF;
}
/* .upload-button:hover{
    background-color: #B2D9FF;
} */
.upload-button>input{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}
.upload-button>button{
    display: flex;
    gap: 17.5px;
    align-items: center;
    justify-content: center;
    color: #D6F1FF;
    font-size: 24px;
    width: 100%;
    height: 100%;
    background-color: transparent;

}
.upload-div{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}
.upload-div>.success{
    font-size: 20px;
    align-self: flex-start;
    color: var(--green-cyan);
    transition: 0.3s ease-in-out;
}
.upload-div>.error{
    font-size: 20px;
    align-self: flex-start;
    color:#FF002E;
    transition: 0.3s ease-in-out;
}
