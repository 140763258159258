

body {
    margin: 0;
    padding: 0;
}

.reset-button {
    all: unset;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #D6F1FF;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.login {
    display: flex;
    flex-direction: column;
    width: 440px;
    gap: 60px;
    padding: 30px;
    background-color: #C2EBFF;
    border: 1px solid #33BBFF;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
}

.login-title {
    font-size: 36px;
    color: #33BBFF;
    width: 100%;
    font-family: 'Work Sans';
    font-weight: 400;
}

.login-title>span {
    font-style: italic;
    font-weight: 600;
}

.input-group {
    /* width: 100%; */
    display: flex;
    color: #33BBFF;
    border-bottom: 1px solid #33BBFF;
    font-family: 'work sans';
    font-weight: 300;
    background-color: #D6F1FF;
    align-items: center;
    padding: 17.5px;
    gap: 15px;
}

.login-input {
    /* width: 90%; */
    /* padding: 17.5px; */
    font-size: 24px;
}

.login-input::placeholder {
    color: #33BBFF;
    font-style: italic;
}

.login-button {
    padding: 17.5px;
    background-color: #33BBFF;
    color: #D6F1FF;
    border: none;
    font-family: 'work sans';
    font-weight: 500;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.error-message{
    font-size: 16px;
    font-weight: 400;
    font-family: 'work sans';
    color: red;
    align-self: center;
}